import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Grid, IconButton, Snackbar, TextField, Typography } from '@mui/material';
import axios from 'axios';
import * as EmailValidator from 'email-validator';

const basURL = process.env.REACT_APP_BASH_URL


// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdatePrinciple() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('error')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(true)
    const [principle, setPriciple] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        id: id
    })
    const [valError, setValError] = useState({
        name: '',
        email: '',
        mobile: '',
        address: ''
    })

    const getSinglePrincipal = async () => {
        return await axios.get(`${basURL}/users/get-user/${id}`).then((response) => {
            setPriciple(response.data.result)
            setLoading(false)
        }).catch((err) => {
            console.log("error on getSinglePrincipal: ", err);
            setLoading(false)
        })
    }

    const handleOnChange = (evt) => {
        setPriciple({ ...principle, [evt.target.name]: evt.target.value })
        setValError({
            name: '',
            email: '',
            mobile: '',
            address: ''
        })
    }

    useEffect(() => {
        getSinglePrincipal()
    }, [loading])

    const handlePrincipleSubmit = async (evt) => {
        evt.preventDefault()
        if (!principle.name) {
            setValError({ ...valError, name: 'Principle name is required!' })
        } else if (!principle.email) {
            setValError({ ...valError, email: 'Principle email is required!' })
        } else if (!EmailValidator.validate(principle.email)) {
            setValError({ ...valError, email: 'Invalid email!' })
        } else if (!principle.mobile) {
            setValError({ ...valError, mobile: 'Mobile number is required!' })
        } else if (principle.mobile.toString().length !== 10) {
            setValError({ ...valError, mobile: 'Invalid mobile number!' })
        } else if (!principle.address) {
            setValError({ ...valError, address: 'Addres is required!' })
        } else {
            return await axios.post(`${basURL}/users/update-user`, principle).then((response) => {
                setSuccess(response.data.msg)
                setCustomVariant('success')
                setOpen(true)
                navigate('/school')
            }).catch((err) => {
                setError(err.response.data.msg)
                setCustomVariant('error')
                setOpen(true)
            })
        }
    }
    return (
        <div >
            <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex' }}>
                <IconButton onClick={() => navigate(-1)} > <KeyboardBackspaceIcon /> </IconButton><Typography variant='h5' component={'div'} fontWeight={700} sx={{ textDecoration: 'underline', textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5 }} >Update Pricipal</Typography>
            </Box>
            <Box component={'form'} noValidate onSubmit={handlePrincipleSubmit} sx={{ paddingRight: 2, marginTop: 2 }}>
                <Grid spacing={2} container>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='text' name='name' id="outlined-basic" label="Parent Name" variant="outlined" error={valError.name ? true : false} helperText={valError.name ? valError.name : ''} value={principle.name} onChange={handleOnChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='email' name='email' id="outlined-basic" label="Parent Email" variant="outlined" error={valError.email ? true : false} helperText={valError.email ? valError.email : ''} value={principle.email} onChange={handleOnChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='number' name='mobile' id="outlined-basic" label="Parent Mobile Number" variant="outlined" error={valError.mobile ? true : false} helperText={valError.mobile ? valError.mobile : ''} value={principle.mobile} onChange={handleOnChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='text' name='address' id="outlined-basic" label="Parent Address" variant="outlined" error={valError.address ? true : false} helperText={valError.address ? valError.address : ''} value={principle.address} onChange={handleOnChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button type='submit' variant='contained' sx={{ width: '30%', marginLeft: '35%', marginRight: '35%' }}>Update</Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default UpdatePrinciple