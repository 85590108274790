import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'

function ImagesCard(props) {
    return (
        <Box sx={{width: 100, height: 100, borderRadius: '50%', alignItems: 'center', justifyContent: 'center'}} >
            <label htmlFor={props?.idForLabel}>
                <img src={props.imagePreview ? props.imagePreview : props.imageCardUrl} alt={''} className={"image"} />
            </label>
            <input type='file' style={{ display: 'none' }} name='photo' id={props?.idForLabel} onChange={props.handleImageOnChage} />
            <Typography variant='body1' component={'h4'}> {props.Label} </Typography>
            <Typography color={"error"} variant='body2'>{props.valError ? props.valError : ''}</Typography>
        </Box>
    )
}

export default ImagesCard