import { Backdrop, Box, Button, CircularProgress, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from "@mui/material/Alert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataGridDemo from '../comman/DataGridDemo';
import axios from 'axios';

const basURL = process.env.REACT_APP_BASH_URL;

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function MakePayment() {

    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    //   const { id } = useParams();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const month = searchParams.get('month');
    const year = searchParams.get('year');


    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("error");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState([])

    const [editedUsers, setEditedUsers] = useState([])

    const date = new Date()


    const getAllbooking = async () => {
        return await axios.get(`${basURL}/payments/get-driver-list`, { headers: options }).then((response) => {
            setUsers(response.data.result)
            setEditedUsers(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    useEffect(() => {
        getAllbooking()
    }, [loading])



    const handleInputChange = (index, field, value) => {
        const updatedPayments = [...editedUsers];
        updatedPayments[index][field] = value;
        updatedPayments[index]["paid_amount"] = updatedPayments[index].payment_amount - value
        updatedPayments[index]["due_amount"] = value
        setEditedUsers(updatedPayments);
    };

    const handleInputChangePaid = (index, field, value) => {
        const updatedPayments = [...editedUsers];
        updatedPayments[index][field] = value;
        let differ = updatedPayments[index].deduction_amount + value
        updatedPayments[index]["due_amount"] = updatedPayments[index].payment_amount - differ
        setEditedUsers(updatedPayments);
    };

    const handlesubmit = async (evt) => {
        evt.preventDefault()
        setLoading(true)
        return await axios.post(`${basURL}/payments/add-payment`, { user: editedUsers, year: date.getFullYear(), month: date.getMonth() + 1 }, { headers: options }).then((response) => {
            setError("")
            setSuccess(response.data.msg)
            setCustomVariant("success")
            setOpen(true)
            setLoading(false)
            navigate('/payment')
        }).catch((err) => {
            setSuccess("")
            setError(err.response.data.msg)
            setCustomVariant("error")
            setOpen(true)
            console.log("error on handlesubmit: ", err);
        })
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: "100%", display: "inline-flex", height: 45, alignItems: 'center' }}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>{" "} <KeyboardBackspaceIcon />{" "}</IconButton>
                <Typography sx={{ textDecorationLine: 'underline' }}>Make Payment</Typography>
            </Box>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false); }} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
            </Snackbar>

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <DataGridDemo /> */}
            <Box component={'form'} onSubmit={handlesubmit}>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Mobile Number</TableCell>
                                <TableCell>Payment Amount</TableCell>
                                <TableCell>Deduction Amount</TableCell>
                                <TableCell>Paid Amount</TableCell>
                                <TableCell>Due Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {editedUsers.map((item, ind) => {
                                return (
                                    <TableRow key={ind}>
                                        <TableCell>{item?.driver?.name}</TableCell>
                                        <TableCell>{item?.driver?.email}</TableCell>
                                        <TableCell>{item?.driver?.mobile}</TableCell>
                                        <TableCell>{item?.payment_amount}</TableCell>
                                        <TableCell>
                                            {/* {console.log("check", parseFloat(item?.deduction_amount > parseFloat(item?.payment_amount) ? true : false))} */}
                                            <TextField
                                                type="number"
                                                name="deduction_amount"
                                                id="deduction-amount"
                                                label="Deduction Amount"
                                                variant="standard"
                                                value={parseFloat(item?.deduction_amount)}
                                                onChange={(e) => handleInputChange(ind, "deduction_amount", parseFloat(e.target.value))}
                                                fullWidth
                                                required
                                            // error={parseFloat(item?.deduction_amount > item?.payment_amount ? true : false)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                name="paid_amount"
                                                id="paid-amount"
                                                label="Paid Amount"
                                                variant="standard"
                                                value={parseFloat(item?.paid_amount)}
                                                onChange={(e) => handleInputChangePaid(ind, "paid_amount", parseFloat(e.target.value))}
                                                fullWidth
                                                required
                                            />
                                        </TableCell>
                                        {/* <TableCell>{item?.payment_amount - item?.paid_amount}</TableCell> */}
                                        <TableCell>{item?.due_amount ? item?.due_amount : 0}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>

                    <Box sx={{ width: '100%', display: 'flex' }}>
                        <Box sx={{ flexGrow: 1 }} />
                        {editedUsers.length <= 0 ? "" : <Button variant='contained' sx={{ m: 1 }} type='submit'>Submit</Button>}
                        {/* <Button variant='contained' sx={{ m: 1 }} type='submit'>Submit</Button> */}
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}


export default MakePayment