import { Backdrop, Box, CircularProgress, Grid, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardComp from '../utils/CardComp'
import axios from 'axios';
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function ReportCommission() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };

    const [parents, setParents] = useState([])
    const [drivers, setDrivers] = useState([])
    const [teachers, setTeachers] = useState([])


    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();


    // get-user-by-role
    const getAllReferParent = async () => {
        return await axios.get(`${bashUrl}/refers/get-all?role=parent`, { headers: options }).then((response) => {
            setParents(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    const getAllReferTeacher = async () => {
        return await axios.get(`${bashUrl}/refers/get-all?role=teacher`, { headers: options }).then((response) => {
            setTeachers(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }
    const getAllReferDriver = async () => {
        return await axios.get(`${bashUrl}/refers/get-all?role=driver`, { headers: options }).then((response) => {
            setDrivers(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    useEffect(() => {
        getAllReferParent()
        getAllReferTeacher()
        getAllReferDriver()
    }, [loading])

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <Grid spacing={2} container>
                <Grid item xs={3}>
                    {/* <CardComp heading={"Number of Parents"} count={parents.length} linkTo={"parent"} customBGColor={"lightgrey"} activeCount={parents.filter((item) => item.status === "active").length} setMenu={setMenu} /> */}
                    <CardComp heading={"Parent Refered"} count={parents ? parents.length : 0} customBGColor={"lightgrey"} activeCount={100} />
                </Grid>

                <Grid item xs={3}>
                    <CardComp heading={"Driver Refered"} count={drivers ? drivers.length : 0} customBGColor={"lightgrey"} activeCount={100} />
                </Grid>
                <Grid item xs={3}>
                    <CardComp heading={"Teacher Refered"} count={teachers ? teachers.length : 0} customBGColor={"lightgrey"} activeCount={100} />
                </Grid>
                {/* <Grid item xs={3}>
                    <CardComp heading={"Driver Wise (Total Payment)"} count={50} customBGColor={"lightgrey"} activeCount={100} />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default ReportCommission