import React, { useEffect, useState } from 'react'
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import * as EmailValidator from 'email-validator';


const basURL = process.env.REACT_APP_BASH_URL
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddAgent() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const navigate = useNavigate();
    const { id } = useParams()

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const [imgPreview, setImgPreview] = useState('')
    const [voterPreview, setVoterPreview] = useState('')

    const [areas, setAreas] = useState([])
    const [agent, setAgent] = useState({
        name: '',
        area: '',
        mobile: '',
        email: '',
        password: '',
        conPass: '',
        role: 'agent',
    })

    const [valAgent, setValAgent] = useState({
        name: '',
        area: '',
        mobile: '',
        email: '',
        password: '',
        conPass: '',
        role: 'agent',

    })

    const getSingleUser = async () => {
        return await axios.get(`${basURL}/users/get-user/${id}`, { headers: options }).then((response) => {
            setAgent(response.data.result)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log("error on getSingleUser: ", err);
        })
    }

    const getArea = async () => {
        return await axios.get(`${basURL}/area/get-all-area`).then((response) => {
            setAreas(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getting area: ", error);
            setLoading(false)
        })
    }

    const handleOnChange = (evt) => {
        setAgent({ ...agent, [evt.target.name]: evt.target.value })
        setValAgent({ name: '', age: '', gender: '', photo: '', identityCard: '', marriedStatus: '', address: '', mobile: '', role: 'agent' })
    }

    const handlOnSelectChange = async (evt) => {
        setValAgent('')
        setAgent({ ...agent, area: evt.target.value })
    }

    const handleImageOnChage = (e) => {
        setImgPreview(URL.createObjectURL(e.target.files[0]))
        setAgent({ ...agent, [e.target.name]: e.target.files[0] });
        // setValErrSchool({ ...valErrSchool, address: "" });
    };

    const handleImageOnChageVoter = (e) => {
        setVoterPreview(URL.createObjectURL(e.target.files[0]))
        setAgent({ ...agent, [e.target.name]: e.target.files[0] });
        // setValErrSchool({ ...valErrSchool, address: "" });
    };

    useEffect(() => {
        if (id) {
            getSingleUser()
        }
        getArea()
    }, [loading])

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        if (!agent.name) {
            setValAgent({ ...valAgent, name: 'Name is required!' })
        } else if (!agent.mobile) {
            setValAgent({ ...valAgent, mobile: 'Mobile number is required!' })
        } else if (agent.mobile.toString().length !== 10) {
            setValAgent({ ...valAgent, mobile: 'Invalid mobile number!' })
        } else if (!agent.email) {
            setValAgent({ ...valAgent, email: 'Email is required!' })
        } else if (!EmailValidator.validate(agent.email)) {
            setValAgent({ ...valAgent, email: 'Invalid email' })
        } else if (!agent.area) {
            setValAgent({ ...valAgent, area: 'Area is required!' })
        } else if (id) {
            return await axios.post(`${basURL}/users/update-agent`, agent, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                navigate(-1)
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
            })
        } else if (!agent.password) {
            setValAgent({ ...valAgent, password: 'Password is required!' })
        } else if (!agent.conPass) {
            setValAgent({ ...valAgent, conPass: 'Confirm Password is required!' })
        } else if (agent.password !== agent.conPass) {
            setValAgent({ ...valAgent, conPass: 'Password and Confirm Password are not matched!' })
        } else {
            return await axios.post(`${basURL}/users/register`, agent, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                navigate(-1)
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
            })
        }
    }

    return (
        <Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} key={'top' + 'right'}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            <Box sx={{ display: 'flex' }}>
                <IconButton onClick={() => navigate(-1)} > <KeyboardBackspaceIcon /> </IconButton><Typography variant='h5' component={'div'} fontWeight={700} sx={{ textDecoration: 'underline', textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5 }} >{id ? "Update" : "Add"} Agent</Typography>
            </Box>

            <Box component={'form'} noValidate onSubmit={handleSubmit} sx={{ paddingRight: 2, marginTop: 2 }}>
                <Grid spacing={2} container>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='text' name='name' id="outlined-basic" label="Agent Name" variant="outlined" error={valAgent.name ? true : false} helperText={valAgent.name ? valAgent.name : ''} value={agent.name} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='number' name='mobile' id="outlined-basic" label="Agent mobile" variant="outlined" error={valAgent.mobile ? true : false} helperText={valAgent.mobile ? valAgent.mobile : ''} value={agent.mobile} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='eamil' name='email' id="outlined-basic" label="Email" variant="outlined" error={valAgent.email ? true : false} helperText={valAgent.email ? valAgent.email : ''} value={agent.email} onChange={handleOnChange} fullWidth required />
                    </Grid>


                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Area</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={agent.area} label="Area" onChange={handlOnSelectChange}>
                                {areas.map((item, ind) => {
                                    return <MenuItem key={ind} value={item.name}>{item?.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {/* <TextField type="text" name="area" id="outlined-basic" label="Area" variant="outlined" error={valErrSchool.area ? true : false} helperText={valErrSchool.area ? valErrSchool.area : ""} value={school.area} onChange={handleChangeSchool} fullWidth required /> */}
                        {valAgent.area ? (<Typography color={"error"} variant="caption">{valAgent.area}</Typography>) : ("")}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='password' name='password' id="outlined-basic" label="Password" variant="outlined" error={valAgent.password ? true : false} helperText={valAgent.password ? valAgent.password : ''} value={agent.password} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='password' name='conPass' id="outlined-basic" label="Confirm Password" variant="outlined" error={valAgent.conPass ? true : false} helperText={valAgent.conPass ? valAgent.conPass : ''} value={agent.conPass} onChange={handleOnChange} fullWidth required />
                    </Grid>


                    {/* <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ width: '100%', height: 150, border: '1px dashed black', borderRadius: 1 }}>
                            <img src={imgPreview} alt="" style={{ width: '100%', height: '100%', borderRadius: '1px' }} />
                        </Box>
                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ width: '100%', height: 150, display: 'flex', alignItems: 'center', }}>
                            <label htmlFor="schoolImage" style={{ width: '70%', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1976d2', color: 'white', borderRadius: '5px', cursor: 'pointer' }}>Upload Image</label>
                            <TextField sx={{ display: 'none' }} type="file" name="image" id="schoolImage" variant="outlined" onChange={handleImageOnChage} /* error={valAgent.image ? true : false} helperText={valErrSchool.image ? valErrSchool.image : ""}  *//* fullWidth required />
                        </Box>
                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ width: '100%', height: 150, border: '1px dashed black', borderRadius: 1 }}>
                            <img src={voterPreview} alt="" style={{ width: '100%', height: '100%', borderRadius: '1px' }} />
                        </Box>
                    </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={6}>
                        <Box sx={{ width: '100%', height: 150, display: 'flex', alignItems: 'center', }}>
                            <label htmlFor="voterImg" style={{ width: '70%', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1976d2', color: 'white', borderRadius: '5px', cursor: 'pointer' }}>Upload Image</label>
                            <TextField sx={{ display: 'none' }} type="file" name="image" id="voterImg" variant="outlined" onChange={handleImageOnChageVoter} /* error={valAgent.image ? true : false} helperText={valErrSchool.image ? valErrSchool.image : ""}  *//* fullWidth required />
                        </Box>
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12}>
                        <Button type='submit' variant='contained' sx={{ width: '30%', marginLeft: '35%', marginRight: '35%' }}>Submit</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AddAgent