import { Box, Typography } from '@mui/material'
import React from 'react'

const textColor = "#3684f0";

function HeadLine({ title }) {
    return (
        <Box sx={{ background: textColor, width: '100%', height: 30, px: 1, lineHeight: 30 }}><Typography color={'white'}>{title}</Typography></Box>
    )
}

export default HeadLine