import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import MuiAlert from "@mui/material/Alert";

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const bashUrl = process.env.REACT_APP_BASH_URL;

function ResetPassword({ admin }) {

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    // console.log("admin: ", admin);
    const [show, setShow] = useState({
        old: true,
        new: true,
        con: true
    })
    const [pass, setPass] = useState({
        old: '',
        new: '',
        con: ''
    })
    const [valPass, setValPass] = useState({
        old: '',
        new: '',
        con: ''
    })

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOnChange = evt => {
        setPass({ ...pass, [evt.target.name]: evt.target.value })
        setValPass({
            old: '',
            new: '',
            con: ''
        })
    }


    const handleSubmit = async (evt) => {
        evt.preventDefault()
        if (!pass.old) {
            setValPass({ old: 'Old password required!' })
        } else if (!pass.new) {
            setValPass({ new: 'New Password is required!' })
        } else if (!pass.con) {
            setValPass({ con: 'Confirm Password is required!' })
        } else if (pass.new !== pass.con) {
            setValPass({ con: 'New password and confirm password are not matched!' })
        } else {
            return await axios.post(`${bashUrl}/users/reset-password`, { id: 39, old: pass?.old, password: pass?.new }).then((response) => {
                // console.log("response: ", response);
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setPass({ old: '', new: '', con: '' })
                setValPass({ old: '', new: '', con: '' })
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
                console.log("error: ", error);
            })
        }
    }


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            <Box component={'form'} noValidate onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={12} sm={12} md={12}>
                        {/* <TextField type={show.old ? 'password' : 'text'} name='old' id="outlined-basic" label="Old Password" variant="outlined" error={valPass.old ? true : false} helperText={valPass.old ? valPass.old : ''} value={pass.old} onChange={handleOnChange} fullWidth required /> */}
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel htmlFor="outlined-adornment-password-old">Old Password</InputLabel>
                            <OutlinedInput id="outlined-adornment-password-old" type={show.old ? 'password' : 'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => setShow({ ...show, old: !show.old })} onMouseDown={handleMouseDownPassword} edge="end">
                                            {show.old ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Old Password"
                                onChange={handleOnChange}
                                name='old'
                                value={pass.old}
                                error={valPass.old ? true : false}
                            />
                            {valPass.old ? (<Typography color={"error"} variant="caption">{valPass.old}</Typography>) : ("")}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        {/* <TextField type={show.old ? 'password' : 'text'} name='old' id="outlined-basic" label="Old Password" variant="outlined" error={valPass.old ? true : false} helperText={valPass.old ? valPass.old : ''} value={pass.old} onChange={handleOnChange} fullWidth required /> */}
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel htmlFor="outlined-adornment-password-new">New Password</InputLabel>
                            <OutlinedInput id="outlined-adornment-password-new" type={show.new ? 'password' : 'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => setShow({ ...show, new: !show.new })} onMouseDown={handleMouseDownPassword} edge="end">
                                            {show.new ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                                onChange={handleOnChange}
                                name='new'
                                value={pass.new}
                                error={valPass.new ? true : false}
                            />
                            {valPass.new ? (<Typography color={"error"} variant="caption">{valPass.new}</Typography>) : ("")}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <TextField type={show.old ? 'password' : 'text'} name='old' id="outlined-basic" label="Old Password" variant="outlined" error={valPass.old ? true : false} helperText={valPass.old ? valPass.old : ''} value={pass.old} onChange={handleOnChange} fullWidth required /> */}
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                            <OutlinedInput id="outlined-adornment-password" type={show.con ? 'password' : 'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => setShow({ ...show, con: !show.con })} onMouseDown={handleMouseDownPassword} edge="end">
                                            {show.con ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                                onChange={handleOnChange}
                                name='con'
                                value={pass.con}
                                error={valPass.con ? true : false}
                            />
                            {valPass.con ? (<Typography color={"error"} variant="caption">{valPass.con}</Typography>) : ("")}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Button type='submit' variant='contained'>Submit</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ResetPassword