import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, Container, Dialog, DialogTitle, Divider, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close';

const basURL = process.env.REACT_APP_BASH_URL

const PopUpModale = ({ openDailog, setOpenDailog, admin, refresh, setRefresh, setSuccess, setError, setCustomVariant, setOpen }) => {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }
    const [tax, setTax] = useState('')
    const [name, setName] = useState('')

    const getTax = async () => {
        return await axios.get(`${basURL}/tax/get-single-tax`, { headers: options }).then((response) => {
            setTax(response.data.result.tax)
            setName(response.data.result.name)
        }).catch((err) => {
            console.log("error on getTax: ", err);
        })
    }

    useEffect(() => {
        getTax()
    }, [openDailog])

    const [taxErr, setTaxErr] = useState('')

    const handleTaxSubmit = async (evt) => {
        evt.preventDefault()

        if (!tax) {
            setTaxErr('Tax can not be empty?')
        } else {
            return await axios.post(`${basURL}/tax/add-tax-update`, { tax, name, id: admin.id }, { headers: options }).then((response) => {
                // console.log("response: ", response);
                setError('')
                setCustomVariant('success')
                setSuccess(response.data.msg)
                setOpen(true)
                setRefresh(!refresh)

                setOpenDailog(false)
            }).catch((err) => {
                console.log("error on handleTaxSubmit: ", err);
                setSuccess("")
                setCustomVariant('error')
                setError(err.response.data.msg)
                setOpen(true)
            })
        }
    }

    return (
        <Dialog open={openDailog} onClose={() => { setOpenDailog(false); setTax(''); setName(''); setTaxErr('') }} scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle id="scroll-dialog-title">Add Or Update Tax </DialogTitle>
                <IconButton sx={{ width: 40, height: 40 }} onClick={() => { setOpenDailog(false); setTax(''); setName(''); setTaxErr('') }}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box component={'form'} noValidate sx={{ width: 400, m: 2 }} onSubmit={handleTaxSubmit}>
                {/* <TextField id="outlined-basic" type='number' required fullWidth name='tax' value={tax} onChange={(e) => setTax(e.target.value)} error={taxErr ? true : false} helperText={taxErr ? taxErr : ''} /> */}
                <TextField type='text' name='name' id="outlined-basic" label="Tax Name" placeholder='Please enter tax Name...' variant="outlined" value={name} onChange={(evt) => { setName(evt.target.value) }} fullWidth sx={{ my: 1 }} />
                <TextField type='number' name='tax' id="outlined-basic" label="Tax" placeholder='Please enter tax i.g(10%)...' variant="outlined" error={taxErr ? true : false} helperText={taxErr ? taxErr : ''} value={tax} onChange={(evt) => { setTax(evt.target.value); setTaxErr('') }} fullWidth required />
                <Button variant='outlined' color='success' fullWidth sx={{ mt: 1 }} type='submit'> Submit </Button>
            </Box>
        </Dialog>
    )
}

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Tax({ admin }) {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'application/json', 'role': 'admin' }
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('error')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)

    const [taxs, setTaxs] = useState('')

    const [openDailog, setOpenDailog] = useState(false)

    const getTaxs = async () => {
        return await axios.get(`${basURL}/tax/get-all-tax`, { headers: options }).then((response) => {
            setTaxs(response.data.result)
            // console.log("response: ", response.data);
            setLoading(false)
        }).catch((err) => {
            console.log("error on getTaxs: ", err);
            setSuccess("")
            setError(err.response.data.msg)
            setCustomVariant("error")
            setOpen(true)
            setLoading(false)

        })
    }

    useEffect(() => {
        getTaxs()
    }, [refresh, loading])

    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <PopUpModale openDailog={openDailog} setOpenDailog={setOpenDailog} admin={admin} refresh={refresh} setRefresh={setRefresh} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} />

            <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>
            {/* <Container> */}
            <Box sx={{ display: 'flex', width: '100%', my: 2 }}>
                <Button variant='contained' onClick={() => setOpenDailog(true)}>Add or update</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-labelledby="tableTitle" >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: "#1976d2" }} align='center'>Name</TableCell>
                            <TableCell sx={{ color: "#1976d2" }} color="#1976d2" align='center'>Tax</TableCell>
                            {/* <TableCell colSpan={3} align='center'>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            <TableCell align='center'>{taxs?.name}</TableCell>
                            <TableCell align='center'>{taxs?.tax ? `${taxs?.tax} %` : ""} </TableCell>
                            {/* <TableCell align='center'>{taxs?.tax}</TableCell> */}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* </Container> */}
        </div>
    )
}

export default Tax