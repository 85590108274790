import { Backdrop, Box, Button, CircularProgress, Container, Dialog, DialogTitle, Divider, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from "@mui/material/Alert";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ModaleComponent from '../popup/ModaleComponent';


const basURL = process.env.REACT_APP_BASH_URL

const PopUpModale = ({ openDailog, setOpenDailog, admin, refresh, setRefresh, setSuccess, setError, setCustomVariant, setOpen }) => {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }

    const [name, setName] = useState("")
    const [noSeat, setNoSeat] = useState("")

    const [valName, setValName] = useState("")
    const [valNoSeat, setValNoSeat] = useState("")

    const handleTaxSubmit = async (evt) => {
        evt.preventDefault()

        if (!name) {
            setValName('Vehicle type name is required!')
        } /* else if (!noSeat) {
            setValNoSeat("no fo seat is required!")
        } */ else {
            return await axios.post(`${basURL}/vehicleType/add-vehicle-type`, { name, noSeat }, { headers: options }).then((response) => {
                setError('')
                setCustomVariant('success')
                setName("")
                setNoSeat("")
                setValName("")
                setValNoSeat("")
                setSuccess(response.data.msg)
                setOpen(true)
                setRefresh(!refresh)
                setOpenDailog(false)
            }).catch((err) => {
                console.log("error on handleTaxSubmit: ", err);
                setSuccess("")
                setCustomVariant('error')
                setError(err.response.data.msg)
                setOpen(true)
                if (err?.response?.status == 401) {
                    localStorage.removeItem("admin-token")
                }
            })
        }
    }

    return (
        <Dialog open={openDailog} onClose={() => { setOpenDailog(false); setName(''); setNoSeat(''); setValName(''); setValNoSeat() }} scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                <DialogTitle id="scroll-dialog-title">Vehicle Type </DialogTitle>
                <IconButton sx={{ width: 40, height: 40 }} onClick={() => { setOpenDailog(false); setOpenDailog(false); setName(''); setNoSeat(''); setValName(''); setValNoSeat() }}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box component={'form'} noValidate sx={{ width: 400, m: 2 }} onSubmit={handleTaxSubmit}>
                {/* <TextField id="outlined-basic" type='number' required fullWidth name='tax' value={tax} onChange={(e) => setTax(e.target.value)} error={taxErr ? true : false} helperText={taxErr ? taxErr : ''} /> */}
                <TextField type='text' name='name' id="outlined-basic" label="Vehicle Type" placeholder='Enter vehilce type name!' variant="outlined" value={name} onChange={(evt) => { setName(evt.target.value); setValName("") }} error={valName ? true : false} helperText={valName ? valName : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                {/* <TextField type='number' name='noSeat' id="outlined-basic" label="No Of Seat" placeholder='Enter no of seat!' variant="outlined" error={valNoSeat ? true : false} helperText={valNoSeat ? valNoSeat : ''} value={noSeat} onChange={(evt) => { setNoSeat(evt.target.value); setValNoSeat('') }} fullWidth required /> */}
                <Button variant='outlined' color='success' fullWidth sx={{ mt: 1 }} type='submit'> Submit </Button>
            </Box>
        </Dialog>
    )
}

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const textColor = "#3684f0"

function VehicleType() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();
    const [search, setSearch] = useState("");

    const [filterdData, setFilterData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [refresh, setRefresh] = useState(false)

    const [openDailog, setOpenDailog] = useState(false)

    const [vehicleTypes, setVehicleTypes] = useState([])

    const [modaleOpen, setModaleOpen] = useState(false)

    const [data, setData] = useState({
        parentId: '',
        id: '',
        role: '',
        name: ''
    })

    // console.log("url: ", basURL);
    const getAllVehicleTypes = async () => {
        return await axios.get(`${basURL}/vehicleType/get-all-vehicle-type`, { headers: options }).then((response) => {
            setLoading(false)
            setVehicleTypes(response.data.result)
            setFilterData(response.data.result);

        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllVehicleTypes: ", err);
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = vehicleTypes.filter((item) => item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])


    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllVehicleTypes()
    }, [loading, refresh])



    return (
        <div >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
            </Snackbar>

            <ModaleComponent openModale={modaleOpen} setOpenModale={setModaleOpen} data={data} setData={setData} setCustomVariant={setCustomVariant} setOpen={setOpen} setSuccess={setSuccess} setError={setError} refresh={refresh} setRefresh={setRefresh} />

            <PopUpModale openDailog={openDailog} setOpenDailog={setOpenDailog} refresh={refresh} setRefresh={setRefresh} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} />

            <Button variant='contained' onClick={() => setOpenDailog(true)}>Add Vehicle Type</Button>


            <Box sx={{ width: '100%', paddingY: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ width: '40%' }}>
                    <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search vehicle type' id="outlined-start-adornment" InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
                </Box>
            </Box>

            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: "#1976d2" }} align='center'>Vehicle Type</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                return (<TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                    <TableCell sx={{ color: "black" }} component="th" scope="row" padding="none" align="center" > {item.name} </TableCell>
                                    <TableCell align='center'>
                                        <Tooltip title="Delete Teacher"><IconButton color='error' onClick={() => { setModaleOpen(true); setData({ ...data, parentId: item.id, role: 'vehicleType', name: item.name }) }}> <DeleteForeverOutlinedIcon /> </IconButton></Tooltip>
                                    </TableCell>
                                </TableRow>)
                            }) : <TableRow>
                                <TableCell colSpan={6} align='center'><Typography >No vehicle type data found!</Typography></TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={vehicleTypes.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>

        </div>
    )
}

export default VehicleType