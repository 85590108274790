import { Box, Button, Dialog, DialogTitle, Divider, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const basURL = process.env.REACT_APP_BASH_URL

function PopUpSetting({ openDailog, setOpenDailog, admin, refresh, setRefresh, setSuccess, setError, setCustomVariant, setOpen }) {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }

    const [setting, setSetting] = useState({
        parentCommision: '',
        refParentAmount: '',
        refDriverAmount: '',
        leadAmountDriver: '',
        leadAmoutParent: '',
        driverCommision: '',
        fine: '',
        teacherCommision: ''
    })

    const [valSetting, setValSetting] = useState({
        parentCommision: '',
        refParentAmount: '',
        refDriverAmount: '',
        leadAmountDriver: '',
        leadAmoutParent: '',
        driverCommision: '',
        fine: '',
        teacherCommision: ''
    })

    const handleOnChange = (evt) => {
        setSetting({ ...setting, [evt.target.name]: evt.target.value })
        setValSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
    }

    const handleClose = () => {
        setOpenDailog(false)
        setSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
        setValSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
    }

    const getSingleSettingData = async () => {
        return await axios.get(`${basURL}/setting/get`, { headers: options }).then((response) => {
            setSetting(response.data.result)
            setRefresh(false)
            // console.log("response: ", response.data.result);
        }).catch((error) => {
            console.log("error on getSingleSettingData: ", error);
        })
    }

    useEffect(() => {
        getSingleSettingData()
    }, [refresh, openDailog])

    const handlSubmit = async (evt) => {
        evt.preventDefault()
        if (!setting.refParentAmount) {
            setValSetting({ ...valSetting, refParentAmount: 'Refer parent amount is required!' })
        } else if (!setting.refDriverAmount) {
            setValSetting({ ...valSetting, refDriverAmount: 'Refer driver amount is required!' })
        } else if (!setting.leadAmountDriver) {
            setValSetting({ ...valSetting, leadAmountDriver: 'Driver lead amount is required!' })
        } else if (!setting.leadAmoutParent) {
            setValSetting({ ...valSetting, leadAmoutParent: 'Parent lead amount is required!' })
        } else if (!setting.parentCommision) {
            setValSetting({ ...valSetting, parentCommision: 'Parent commission is required!' })
        } else if (!setting.driverCommision) {
            setValSetting({ ...valSetting, driverCommision: 'Driver commission is required!' })
        } else if (!setting.teacherCommision) {
            setValSetting({ ...valSetting, teacherCommision: 'Teacher commission is required!' })
        } else if (!setting.fine) {
            setValSetting({ ...valSetting, fine: 'Fine is required!' })
        } else {
            return await axios.post(`${basURL}/setting/add`, setting, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setRefresh(true)
                handleClose()
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
                console.log("error on handlSubmit: ", error);
            })
        }
    }

    // console.log("setSetting: ", valSetting);
    return (
        <Dialog open={openDailog} onClose={handleClose} scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                <DialogTitle id="scroll-dialog-title">Setting</DialogTitle>
                <IconButton sx={{ width: 40, height: 40 }} onClick={handleClose}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box component={'form'} noValidate sx={{ width: 400, m: 2 }} onSubmit={handlSubmit}>
                <TextField type='number' name='refParentAmount' id="outlined-basic" label="Refer Parent Amount" placeholder='Enter refer parent amount!' variant="outlined" value={setting.refParentAmount} onChange={handleOnChange} error={valSetting.refParentAmount ? true : false} helperText={valSetting.refParentAmount ? valSetting.refParentAmount : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='refDriverAmount' id="outlined-basic" label="Refer Driver Amount" placeholder='Enter refer driver amount!' variant="outlined" value={setting.refDriverAmount} onChange={handleOnChange} error={valSetting.refDriverAmount ? true : false} helperText={valSetting.refDriverAmount ? valSetting.refDriverAmount : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='leadAmountDriver' id="outlined-basic" label="Driver Lead Amount" placeholder='Enter driver lead amount!' variant="outlined" value={setting.leadAmountDriver} onChange={handleOnChange} error={valSetting.leadAmountDriver ? true : false} helperText={valSetting.leadAmountDriver ? valSetting.leadAmountDriver : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='leadAmoutParent' id="outlined-basic" label="Parent Lead Amount" placeholder='Enter parent lead amount!' variant="outlined" value={setting.leadAmoutParent} onChange={handleOnChange} error={valSetting.leadAmoutParent ? true : false} helperText={valSetting.leadAmoutParent ? valSetting.leadAmoutParent : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='parentCommision' id="outlined-basic" label="Parent Commision" placeholder='Enter parent commission!' variant="outlined" value={setting.parentCommision} onChange={handleOnChange} error={valSetting.parentCommision ? true : false} helperText={valSetting.parentCommision ? valSetting.parentCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='driverCommision' id="outlined-basic" label="Driver Commission" placeholder='Enter driver commission!' variant="outlined" value={setting.driverCommision} onChange={handleOnChange} error={valSetting.driverCommision ? true : false} helperText={valSetting.driverCommision ? valSetting.driverCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='teacherCommision' id="outlined-basic" label="Teacher Commission" placeholder='Enter teacher commission!' variant="outlined" value={setting.teacherCommision} onChange={handleOnChange} error={valSetting.teacherCommision ? true : false} helperText={valSetting.teacherCommision ? valSetting.teacherCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <TextField type='number' name='fine' id="outlined-basic" label="Fine" placeholder='Enter fine!' variant="outlined" value={setting.fine} onChange={handleOnChange} error={valSetting.fine ? true : false} helperText={valSetting.fine ? valSetting.fine : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <Button variant='outlined' color='success' fullWidth sx={{ mt: 1 }} type='submit'> Submit </Button>
            </Box>
        </Dialog>
    )
}

export default PopUpSetting