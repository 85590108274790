import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios'
import moment from 'moment';
import ModaleComponent from '../popup/ModaleComponent';



const textColor = "#3684f0"
const bashUrl = process.env.REACT_APP_BASH_URL
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL


// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Address() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'application/json', 'role': 'admin' }
    const [address, setAddress] = useState('')
    const [valAddress, setValAddress] = useState('')

    const [filterdData, setFilterData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refresh, setRefresh] = useState(false)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)


    // this is for delete
    const [openModale, setOpenModale] = useState(false)
    const [data, setData] = useState({
        name: '',
        id: '',
        role: ''
    })

    const [addresses, setAddresses] = useState([])

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('success')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState()

    const handlOnChange = (evt) => {
        setValAddress('')
        setAddress(evt.target.value)
    }


    const getAllArea = async () => {
        return await axios.get(`${bashUrl}/area/get-all-area`, { headers: options }).then((response) => {
            setAddresses(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((err) => {
            console.log("error on getAllArea: ", err);
            setLoading(false)
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // adding area to database
    const handleSubmit = async (evt) => {
        setLoading(true)
        evt.preventDefault()
        if (!address) {
            setValAddress("Address is required!")
        } else {
            return await axios.post(`${bashUrl}/area/add-area`, { name: address }, { headers: options }).then((response) => {
                setLoading(false)
                setAddress("")
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setRefresh(!refresh)
            }).catch((err) => {
                setLoading(false)
                console.log("error on addArea: ", err);
                setSuccess("")
                setError(err.response.data.msg)
                setCustomVariant('error')
                setOpen(true)
                if (err?.response?.status == 401) {
                    localStorage.removeItem("admin-token")
                }
            })
        }
    }



    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = addresses.filter((item) => item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])

    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllArea()
    }, [loading, refresh])

    return (
        <div >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>


            <ModaleComponent openModale={openModale} setOpenModale={setOpenModale} data={data} setData={setData} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} />


            <Box component={'form'} noValidate onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                    <Grid item xs={8}>
                        <TextField value={address} type="text" id="outlined-basic" label="Area" variant="outlined" name="address" sx={{ my: 1 }} placeholder='Mango | Sakchi | Bistopur' onChange={handlOnChange} error={valAddress ? true : false} fullWidth />
                        <Typography variant='caption' component={'div'} color={'error'} sx={{ mt: -1 }}>{valAddress ? valAddress : ''}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ width: '100%', paddingX: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 55, mt: 1 }}>
                            <Button sx={{ width: '80%' }} variant='outlined' color='success' fullWidth type='submit'>Submit</Button>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Box sx={{ width: '100%', paddingY: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ width: '40%' }}>
                    <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search by area' id="outlined-start-adornment" InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Area</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>CreatedAt</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                            return (<TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                {/* <TableCell sx={{ color: textColor }} align="left"><Avatar sx={{}} alt={item?.name} src={`${imageUrl}/images/teachers/` + item?.photo} /> </TableCell> */}
                                <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align="center" > {item.name} </TableCell>
                                <TableCell sx={{ color: textColor }} align="center">{moment(item.createdAt).format('LL')}</TableCell>
                                <TableCell sx={{ color: textColor }} align="center"><Tooltip title="Delete Area"><IconButton color='error' onClick={() => { setOpenModale(true); setData({ ...data, id: item.id, role: 'area', name: item.name }) }}> <DeleteForeverOutlinedIcon /> </IconButton></Tooltip></TableCell>
                            </TableRow>)
                        }) : <TableRow>
                            <TableCell colSpan={6} align='center'><Typography >No address data found!</Typography></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={addresses.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </div>
    )
}

export default Address